<template>
<div class="phone">
</div>
<div class="message mulish medium text-center">
  Please rotate your device<br>to portrait mode
</div>
</template>
<style scoped>
.phone {
    height: 50px;
    width: 100px;
    border: 3px solid var(--flame);
    border-radius: 10px;
    animation: rotate 1.5s ease-in-out infinite alternate;
    /* display: none; */
}

.message {
    color: white;
    font-size: 1em;
    margin-top: 40px;
    /* display: none; */
}

@keyframes rotate {
    0% {
        transform: rotate(0deg)
    }

    50% {
        transform: rotate(-90deg)
    }

    100% {
        transform: rotate(-90deg)
    }
}

@media only screen and (max-device-width: 812px) and (orientation: landscape) {

    .phone,
    .message {
        display: block;
    }
}
</style>