<template>
    <div class="project position-relative bg-baltic-sea" :id="`${project.name}-${project.id}`" @mouseenter="lunchInterval()"
        @mouseleave="clearInterval">
        <div class="wrapper">
            <p class="project-name f-65 font-seashell mb-0 thunder-bold text-uppercase">
                {{ project.name }}
            </p>
            <p class="date-range font-seashell mulish f-14">{{ project.date }}</p>
            <div class="tag-container flex-wrap">
                <a target="_blank" :href="tag.link" class="tag f-20 mulish medium font-seashell"
                    v-for="tag in project.buttons" :key="tag.id">
                    {{ tag.title }}
                </a>
            </div>
        </div>
        <div :id="`${project.name}-image-slider`" class="image-slider">
        </div>
    </div>
</template>
<script>
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';

import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

// import required modules
import { EffectFade, Autoplay } from 'swiper/modules';

export default {
    data() {
        return {
            i: 0,
            myInterval: null,
            current: ''
        }
    },
    mounted() {
        if (this.project.images.length > 0 && window.innerWidth > 1199.98)
            document.getElementById(`${this.project.name}-image-slider`).style.backgroundImage = `url(${this.project.images[0]})`;
    },
    beforeDestroy() {
        if (window.innerWidth > 1199.98)
            clearInterval(this.myInterval)
    },
    props: {
        project: Object
    },
    methods: {
        change() {
            this.i = (this.i + 1) % this.project.images.length;
            this.current = this.project.images[this.i];
            document.getElementById(`${this.project.name}-image-slider`).style.backgroundImage = `url(${this.current})`;
        },
        lunchInterval() {
            if (this.project.images.length >= 2 && window.innerWidth > 1199.98)
                this.myInterval = setInterval(this.change, 2500);
        },
        clearInterval() {
            clearInterval(this.myInterval)
        }
    },
    components: {
        Swiper,
        SwiperSlide,
    },
    setup() {
        return {
            modules: [EffectFade, Autoplay],
        };
    },
}
</script>
<style scoped>
.image-slider {
    position: absolute;
    z-index: 0 !important;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-size: 100%;
    background-position-x: center;
    background-repeat: no-repeat;

}

.project:hover .image-slider {
    animation: animatedImage 2.5s linear infinite;
}

@keyframes animatedImage {
    0% {
        background-size: 100%
    }

    100% {
        background-size: 110%
    }

}

.image-slider::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.7;
    background: #242426;
}

.wrapper {
    margin-left: 3.39vw;
    padding-top: 12.9vh;
    position: relative;
    z-index: 1;
}

.image {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.tag-container {
    margin-top: 7vh;
    gap: 14px;
    display: flex;
    opacity: 0;
    transition: all .4s ease-in-out;
}

.image-slider {
    opacity: 0;
    transition: opacity .4s ease-in-out;

}

.project:hover .tag-container,
.project:hover .image-slider {
    display: flex;
    opacity: 1;
    transition: opacity .4s ease-in-out;
}

.tag {
    display: flex;
    padding: 15px;
    justify-content: center;
    align-items: center;
    border-radius: 30px;
    border: 1px solid var(--seashell);
    text-decoration: none;
    cursor: pointer !important
}

.tag:hover ,.tag:active  {
    background: linear-gradient(259deg, #753188 0%, #DA5927 100%);
    border-color: linear-gradient(259deg, #753188 0%, #DA5927 100%)
}

.overlay {
    opacity: 0.7;
    background: #242426;
    position: absolute;
    z-index: 2 !important;

}

.project,
.image {
    width: 22.39vw !important;
    height: 64.03vh !important;
}


.project-name {
    line-height: normal;

}

@media (max-width: 1399.98px) {
    .tag {
        padding: 6px;
    }

    .tag-container {
        gap: 7px;
    }

    .tag:hover ,.tag:active{
        border-color: transparent
    }
}

@media (max-width: 1199.98px) {
    .project .tag-container {
        display: flex;
        opacity: 1;
    }

    .project {
        width: 49.9vw !important;
        height: 50.25vh !important;
    }

    .wrapper {
        margin-left: 6vw;
        margin-right: 3vw;

        padding-top: 8.8vh;
    }

    .tag-container {
        gap: 12px;
    }

    .tag {
        padding: 14px;
    }

}

@media (min-width: 650.98px) and (max-width: 799.98px) {
    .tag {
        padding: 10px;
    }
}

@media (max-width: 575.98px) {
    .project {
        width: 70.5vw !important;
        height: 49.7vh !important;
    }

    .tag-container {
        margin-top: 7vh;
    }

    .tag {
        padding: 9px;
    }
}
</style>