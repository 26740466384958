<template>
    <div class="container-fluid bg-baltic-sea" id="hero-screen">
        <div class="row h-100">
            <div class="col-xl-7 col-12 d-flex flex-column px-0 top-section">
                <div class="logo-container mobile-sticky" @click="clear()">
                    <img src="../../assets/logo.svg" class="" alt="">
                </div>
                <div class="ml-7vw font-seashell text-uppercase thunder-bold slogan-container">
                    <h1 class="f-175 slogan mb-0">
                        we make<br>good brands<br>look great.
                    </h1>
                    <!-- <button class="btn f-26 font-smokey-grey mulish btn-outline-seashell" @click="scroll">
                        Play showreel
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0_89_1244)">
                                <path
                                    d="M10.0054 19.5582C15.2809 19.5582 19.5576 15.2815 19.5576 10.006C19.5576 4.73042 15.2809 0.453735 10.0054 0.453735C4.72981 0.453735 0.453125 4.73042 0.453125 10.006C0.453125 15.2815 4.72981 19.5582 10.0054 19.5582Z"
                                    stroke="#6D6C6F" stroke-width="0.75" stroke-miterlimit="10" />
                                <path d="M5.23047 10.006H13.8036" stroke="#6D6C6F" stroke-width="0.75"
                                    stroke-miterlimit="10" />
                                <path
                                    d="M10.8416 13.6597L10.2207 13.003L13.4565 10.006L10.2207 6.99705L10.8416 6.34033L14.7819 10.006L10.8416 13.6597Z"
                                    fill="#6D6C6F" />
                            </g>
                            <defs>
                                <clipPath id="clip0_89_1244">
                                    <rect width="20" height="20" fill="white" />
                                </clipPath>
                            </defs>
                        </svg>


                    </button> -->
                </div>

            </div>
            <div class="col-xl-5 col-12 px-0 d-flex justify-content-xl-end link-cards-container">
                <LinkCard title="branding" img="branding.svg" />
                <LinkCard title="marketing" img="marketing.svg" />
            </div>
        </div>
    </div>
</template>
  
<script>
import LinkCard from '../UI/LinkCard.vue'
export default {
    components: {
        LinkCard
    },
    methods: {
        scroll() {
            window.scrollBy(0, window.innerHeight);
        },
        clear() {
            this.$store.dispatch('setActiveTap', '')
        }
    }
}
</script>
<style scoped>
#hero-screen {
    height: 100vh;
    /* overflow-y: hidden; */
}

.logo-container {
    margin-top: 8.54vh;
}

.logo-container {
    margin-left: 3.39vw;
}

.logo-container img {
    width: 10.416vw;
}

.ml-7vw {
    margin-left: 3.39vw;
}

.slogan {
    line-height: .85;
}

.slogan-container {
    margin-bottom: 14.9vh;
    margin-top: auto;
}

.btn-outline-seashell,
.btn-outline-seashell:hover {
    border: 1px solid var(--smokey-grey);
    border-radius: 50px;
    /* margin-top: 2vh; */
}

.btn-outline-seashell {
    padding: 2vh 1.25vw;
}



@media (max-width: 1199.98px) {

    .mobile-sticky {
        position: fixed;
        background-color: #242426;
        width: 100%;
        z-index: 3;
    }

    .slogan {
        line-height: .82;
    }

    .logo-container {
        padding-left: 6vw;
        margin-left: 0 !important;
    }

    .logo-container {
        margin-top: 0 !important;
        /* padding-top: 4.2vh;
        padding-bottom: 4.2vh; */
        height: 13vh;
        display: flex;
    }

    .slogan-container {
        margin-bottom: 5.36vh;
    }

    .logo-container {
        margin-top: 4.2vh;
    }

    .logo-container img {
        width: 20.3vw;
    }

    .logo-container {
        margin-left: 6vw;
    }

    .ml-7vw {
        margin-left: 6vw;
    }

    .btn-outline-seashell {
        padding: 1.1vh 3vw;
        margin-top: 3vh;
    }

    .link-cards-container {
        height: fit-content;
        margin-top: auto;
    }

    .top-section {
        height: 54.11vh;
    }
}

@media (min-width: 650.98px) and (max-width: 799.98px) {
    .logo-container {
        padding-top: 4vh;
        padding-bottom: 4vh;
    }

    .slogan-container {
        margin-bottom: 3vh;
    }
}

@media (max-width: 575.98px) {
    .logo-container img {
        width: 28vw;
    }

    .link-cards-container {
        overflow: auto;
        width: 100vw;
    }
}

@media (max-width: 574.98px)and (max-height:600px) {
    .logo-container {
        margin-top: 0 !important;
        height: 11vh;
    }

    .slogan-container {
        margin-bottom: 2.36vh;
    }
}
</style>
  