<template>
    <swiper slidesPerView="auto" :spaceBetween="0" :freeMode="true" :pagination="{
        clickable: true,
    }" :modules="modules" class="mySwiper our-projects-slider" :navigation="true">
        <swiper-slide class="slide" v-for="project in projects" :key="project.id">
            <Project :project="project" />
        </swiper-slide>
    </swiper>
</template>
<script>
import Project from '../UI/Project.vue'
import 'swiper/css/navigation';

// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Navigation } from 'swiper/modules';

// Import Swiper styles
import 'swiper/css';

import 'swiper/css/free-mode';
// import './style.css';
// import required modules

import { FreeMode } from 'swiper/modules';
export default {
    mounted() {
        document.querySelector('.our-projects-slider .swiper-button-prev').innerHTML = `
        <button class="btn font-smokey-grey f-14">
                        Drag Right
                        <img src="${require(`@/assets/img/arrow.svg`)}" class=" mx-1 arrows" alt="tms-arrow">
</button>
        `;
        document.querySelector('.our-projects-slider .swiper-button-next').innerHTML = `
          <button class="btn font-smokey-grey f-14">
            <img src="${require(`@/assets/img/arrow.svg`)}" class="mx-1 arrow arrows" alt="tms-arrow">

                        Drag Left
          </button>
        `;
    },
    props: {
        projects: Array,
    },
    components: {
        Swiper,
        SwiperSlide,
        Project
    },
    setup() {
        return {
            modules: [FreeMode, Navigation],
        };
    },
};
</script>
<style scoped>
.mySwiper {
    cursor: grab
}

.mySwiper:active {
    cursor: grabbing
}

.swiper-slide {
    /*  */
    width: fit-content;
    height: fit-content;
    border: 1px solid var(--seashell);
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
}

.mySwiper {
    padding-top: 9vh;
}
@media (min-width: 1200px) {
    .mySwiper {
    padding-top: 0vh;
}
}
</style>