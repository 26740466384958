<template>
  <div class="rotate position-fixed t-0 b-0 l-0 r-0" v-show="showError">
    <RotateMessage />
  </div>
  <div>
    <HeroSection />
    <OurWorks />
    <div class="service" v-show="!init && this.oldStatus != 'marketing'"
      :class="ActiveTap == 'branding' ? ' animate__animated  animate__slideInRight' : 'animate__animated  animate__slideOutRight'">
      <ServiceTemplate title="branding"
        :desc="`We build brands<br class='d-none d-xl-block'>that<br class='d-xl-none d-block'>make you<br class='d-none d-xl-block'>stand out.`"
        icon="branding-title.svg" imgUrl="branding-service.svg" mobileImgUrl="branding-service-mobile.svg"
        xsImgUrl="branding-service-xs.svg" />
    </div>
    <div class="service" v-show="!init && this.oldStatus != 'branding'"
      :class="ActiveTap == 'marketing' ? ' animate__animated  animate__slideInRight' : 'animate__animated  animate__slideOutRight'">
      <ServiceTemplate title="marketing"
        :desc="`We drive brand<br class='d-none d-xl-block'>success<br class='d-xl-none d-block'>with<br class='d-none d-xl-block'>our strategies`"
        icon="marketing-title.svg" imgUrl="marketing-service.svg" mobileImgUrl="marketing-service-mobile.svg"
        xsImgUrl="marketing-service-xs.svg" />
    </div>
  </div>
</template>

<script>
import HeroSection from '../components/sections/HeroSection.vue'
import OurWorks from '../components/sections/OurWorks.vue'
import ServiceTemplate from '../components/sections/ServiceTemplate.vue'
import RotateMessage from '../components/UI/RotateMessage.vue'
export default {
  data() {
    return {
      init: true,
      oldStatus: '',
      showError: false
    }
  },
  updated() {
    this.init = false;
  },
  mounted() {
    this.checkRotate();
    this.$nextTick(() => {
      window.addEventListener("resize", this.checkRotate);
    });
  },
  components: {
    RotateMessage,
    HeroSection,
    OurWorks,
    ServiceTemplate
  },
  computed: {
    ActiveTap() {
      if (this.$store.getters.getActiveTap != '')
        this.oldStatus = this.$store.getters.getActiveTap
      return this.$store.getters.getActiveTap
    }
  },
  methods: {
    checkRotate() {
      var w = window.innerWidth, h = window.innerHeight;
      if (w > h && w < 1200)
        this.showError = true
      else
        this.showError = false
      console.log(this.showError)
    }
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.checkRotate);
  },
}
</script>
<style scoped>
.service {
  position: fixed;
  top: 0%;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
}

.t-0 {
  top: 0
}

.b-0 {
  bottom: 0
}

.l-0 {
  left: 0
}

.r-0 {
  right: 0
}

.rotate {
  background-color: #242426;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 5;
}

.overflowy-scroll {
  /* overflow-y: auto; */
}
</style>
