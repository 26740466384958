<template>
    <div :class="`link-card d-flex flex-column justify-content-end align-items-center pointer ${title}`"
        @click="setActiveTap(title)">
        <div class="mb-180 w-100" v-if="title == 'branding'">
            <svg width="225" height="261" viewBox="0 0 225 261" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M225 148.5C225 178.337 213.147 206.952 192.05 228.05C170.952 249.147 142.337 261 112.5 261C82.6631 261 54.0483 249.147 32.9505 228.05C11.8526 206.952 0 178.337 0 148.5H76.7025C76.7025 157.994 80.474 167.099 87.1873 173.813C93.9007 180.526 103.006 184.297 112.5 184.297C121.994 184.297 131.099 180.526 137.813 173.813C144.526 167.099 148.297 157.994 148.297 148.5H225Z"
                    fill="#1B1B1D" id="half-1" />
                <path
                    d="M225 36C225 65.8369 213.147 94.4517 192.05 115.55C170.952 136.647 142.337 148.5 112.5 148.5C82.6631 148.5 54.0483 136.647 32.9505 115.55C11.8526 94.4517 0 65.8369 0 36H225Z"
                    fill="#1B1B1D" id="half-2" />
                <path
                    d="M0.752513 147.75C0.949348 118.383 12.7005 90.2612 33.4808 69.4808C54.438 48.5236 82.8621 36.75 112.5 36.75C142.138 36.75 170.562 48.5236 191.519 69.4808C212.3 90.2612 224.051 118.383 224.247 147.75H0.752513Z"
                    stroke="#DA5927" stroke-width="1.5" stroke-miterlimit="10" />
                <circle cx="111.938" cy="36.5625" r="35.8125" stroke="#DA5927" stroke-width="1.5" />
            </svg>

        </div>
        <div class="mb-180 w-100" v-else-if="title == 'marketing'">
            <svg width="225" height="225" viewBox="0 0 225 225" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path id="circle-1"
                    d="M112.5 0V76.7025C119.58 76.7025 126.501 78.802 132.388 82.7355C138.275 86.6689 142.863 92.2597 145.573 98.8009C148.282 105.342 148.991 112.54 147.61 119.484C146.228 126.428 142.819 132.806 137.813 137.813C132.806 142.819 126.428 146.228 119.484 147.61C112.54 148.991 105.342 148.282 98.8009 145.573C92.2597 142.863 86.6689 138.275 82.7355 132.388C78.802 126.501 76.7025 119.58 76.7025 112.5H0C0 134.75 6.59801 156.501 18.9597 175.002C31.3213 193.502 48.8914 207.922 69.4481 216.436C90.0048 224.951 112.625 227.179 134.448 222.838C156.27 218.497 176.316 207.783 192.049 192.049C207.783 176.316 218.497 156.27 222.838 134.448C227.179 112.625 224.951 90.0048 216.436 69.4481C207.922 48.8914 193.502 31.3213 175.002 18.9597C156.501 6.59801 134.75 0 112.5 0Z"
                    fill="#1B1B1D" />
                <path id="circle-2"
                    d="M148.089 112.5C148.174 117.254 147.31 121.976 145.549 126.393C143.788 130.809 141.165 134.83 137.834 138.222C134.502 141.613 130.527 144.307 126.143 146.146C121.759 147.985 117.052 148.932 112.297 148.932C107.543 148.932 102.836 147.985 98.4519 146.146C94.0676 144.307 90.0934 141.613 86.7615 138.222C83.4295 134.83 80.8066 130.809 79.0457 126.393C77.2848 121.976 76.4213 117.254 76.5056 112.5H148.089Z"
                    fill="#1B1B1D" />
                <circle cx="111.937" cy="111.937" r="63.9375" stroke="#753188" stroke-width="1.5" />
                <circle cx="111.937" cy="111.937" r="35.8125" stroke="#753188" stroke-width="1.5" />
            </svg>
        </div>
        <div class="d-flex justify-content-center align-items-basline  mb-100">
            <h2 class="title f-70 thunder-bold font-seashell text-uppercase">{{ title }}</h2>
            <img :src="require(`@/assets/img/arrow.svg`)" class="mx-2" alt="">
        </div>
    </div>
</template>
<script>
export default {
    props: {
        img: String,
        title: String
    },
    methods: {
        setActiveTap(active) {
            this.$store.dispatch('setActiveTap', active)
        }
    }
}
</script>
<style scoped>
.pointer {
    cursor: pointer;
}

.link-card {
    width: 18.22vw;
    height: 100%;
    border: 1px solid var(--smokey-grey);
}

svg {
    width: 72.8%;
    height: auto;
    margin: auto;
    display: flex;
}

.mb-180 {
    margin-bottom: 19.2vh;
}

.mb-100 {
    margin-bottom: 10.67vh;
}

.link-card.branding:hover {
    background: linear-gradient(180deg, rgba(218, 89, 39, 0.00) 0%, #DA5927 165.85%);
}

.link-card.marketing:hover {
    background: linear-gradient(180deg, rgba(117, 49, 136, 0.00) 0%, #753188 165.85%);
}

.link-card.marketing:hover #circle-2,
.link-card.marketing:hover #circle-1 {
    fill: #302035
}

.link-card.branding:hover #half-1,
.link-card.branding:hover #half-2 {
    fill: #4D2A1D
}

@media (max-width: 1399.98px) {
    svg {
        width: 54.8%;
    }

    .mb-180 {
        margin-bottom: 14vh;
    }
}

@media (max-width: 1199.98px) {
    .mb-180 {
        margin-bottom: 7.78vh;
    }

    .mb-100 {
        margin-bottom: 4.5vh;
    }

    .link-card {
        width: 50vw;
        height: 45.89vh;
    }

    svg {
        width: 48%;
    }
}

@media (min-width: 650.98px) and (max-width: 799.98px) {
    .link-card {
        width: 50vw;
        height: 44vh;
    }
}

@media (max-width: 575.98px) {
    .link-card {
        min-width: 70.5vw;
        height: 46vh;
    }

    svg {
        width: 45.45%
    }

}

@media (max-width: 574.98px)and (max-height:600px) {
    .link-card {
        min-width: 60vw;
    }
    }
</style>

    