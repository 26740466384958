<template>
    <div class="container-fluid bg-baltic-sea" id="our-works-screen">
        <div class="row h-100">
            <div class="col-12 px-0">
                <div class="title ml-7vw mt-8vh ">
                    <div class="d-flex">
                        <p class="f-47 font-seashell text-uppercase thunder-bold mb-0 our">our</p>
                        <hr />
                    </div>
                    <p class="f-180 font-flame text-uppercase thunder-bold mb-0">Works</p>
                </div>
            </div>
            <!-- <div class="col-12 px-0">
                <div class=" justify-content-between mx-3vw d-lg-none d-flex">
                    <button class="btn font-smokey-grey f-14" @click="setForword()" :disabled="isDisabledForword">
                        <img src="../../assets/img/arrow.svg" class="arrow mx-2 arrows" alt="tms-arrow">
                        Drag Left</button>
                    <button class="btn font-smokey-grey f-14" @click="setBackword()" :disabled="isDisabledBackword">
                        Drag Right
                        <img src="../../assets/img/arrow.svg" class="mx-2 arrows" alt="tms-arrow">
                    </button>
                </div>
            </div> -->
            <div class="col-12 px-0 mt-auto d-flex">
                <ProjectsSlider :projects="pros"/>
            </div>
        </div>
    </div>
</template>
  
<script>
import ProjectsSlider from '../UI/ProjectsSlider.vue'
export default {
    data() {
        return {
            show: false,
            pros: [{ name: 'Dybak', date: '2022-2023', id: '1', images: ['https://test.ecart.sy//storage/products//Wn94jKdqFHFnOBKXyS5HL9pIfTSl4WSPEYJKEBkh.jpg', 'https://test.ecart.sy//storage/products//mmMmMeoW4uBq6jVn4XtZe0nKa5R3T5j7ILb8PSUk.jpg'], buttons: [{ id: '', title: 'Identity', link: '' }, { id: '', title: 'Character Design', link: '' }, { id: '', title: 'UI/UX Design', link: '' }] },
            { name: 'Easy buy Africa', date: '2022-2023', id: '2', images: ['https://test.ecart.sy//storage/products//Wn94jKdqFHFnOBKXyS5HL9pIfTSl4WSPEYJKEBkh.jpg', 'https://test.ecart.sy//storage/products//mmMmMeoW4uBq6jVn4XtZe0nKa5R3T5j7ILb8PSUk.jpg'], buttons: [{ id: '', title: 'Identity', link: '' }, { id: '', title: 'Character Design', link: '' }, { id: '', title: 'UI/UX Design', link: '' }] },
            { name: 'Ehjizli', date: '2022-2023', id: '3', images: ['https://test.ecart.sy//storage/products//Wn94jKdqFHFnOBKXyS5HL9pIfTSl4WSPEYJKEBkh.jpg', 'https://test.ecart.sy//storage/products//mmMmMeoW4uBq6jVn4XtZe0nKa5R3T5j7ILb8PSUk.jpg'], buttons: [{ id: '', title: 'Identity', link: '' }, { id: '', title: 'Character Design', link: '' }, { id: '', title: 'UI/UX Design', link: '' }] },
            { name: 'Gemini', date: '2022-2023', id: '4', images: ['https://test.ecart.sy//storage/products//Wn94jKdqFHFnOBKXyS5HL9pIfTSl4WSPEYJKEBkh.jpg', 'https://test.ecart.sy//storage/products//mmMmMeoW4uBq6jVn4XtZe0nKa5R3T5j7ILb8PSUk.jpg'], buttons: [{ id: '', title: 'Identity', link: '' }, { id: '', title: 'Character Design', link: '' }, { id: '', title: 'UI/UX Design', link: '' }] },
            { name: 'Jabour', date: '2022-2023', id: '5', images: ['https://test.ecart.sy//storage/products//Wn94jKdqFHFnOBKXyS5HL9pIfTSl4WSPEYJKEBkh.jpg', 'https://test.ecart.sy//storage/products//mmMmMeoW4uBq6jVn4XtZe0nKa5R3T5j7ILb8PSUk.jpg'], buttons: [{ id: '', title: 'Identity', link: '' }, { id: '', title: 'Character Design', link: '' }, { id: '', title: 'UI/UX Design', link: '' }] },
            { name: 'Kaffou', date: '2022-2023', id: '6', images: ['https://test.ecart.sy//storage/products//Wn94jKdqFHFnOBKXyS5HL9pIfTSl4WSPEYJKEBkh.jpg', 'https://test.ecart.sy//storage/products//mmMmMeoW4uBq6jVn4XtZe0nKa5R3T5j7ILb8PSUk.jpg'] }],
        }
    },
    mounted() {
        this.show = true;
    },
    computed: {
        showSlider() {
            return this.show
        }
    },
    components: {
        ProjectsSlider
    },
}
</script>
<style scoped>
#our-works-screen {
    height: 100vh;
}

.mt-8vh {
    margin-top: 8.54vh;
}

.ml-7vw {
    margin-left: 3.39vw;
}

.title {
    line-height: .9;
    width: fit-content;
}

.title hr {
    border-top: none;
    border-bottom: 2px solid var(--seashell);
    width: 81%;
    margin-top: auto !important;
    margin-left: auto;
}

.mx-3vw {
    margin-right: 6vw;
    margin-left: 6vw;
}

@media (max-width: 1399.98px) {
    .f-26 {
        font-size: 18px !important;
    }
}

@media (max-width: 1199.98px) {
    .ml-7vw {
        margin-left: 6vw;
    }

    .mt-8vh {
        margin-top: 17vh;
    }

    .arrow {
        transform: rotate(180deg);
    }

    .btn,
    .btn:hover {
        border: 1px solid var(--smokey-grey);
        border-radius: 33px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 15px;
    }

    .btn:disabled {
        border-color: #3A393C;
        color: #3A393C
    }
}

@media (max-width: 575.98px) {
    .btn ,.btn:hover{
        padding-top: 10px;
        padding-bottom: 10px;        
        padding-left: 10px;
        padding-right: 10px;
    }

    .arrows {
        width: 12px;
    }
}
@media (max-width: 574.98px)and (max-height:600px) {
    .btn, .btn:hover {
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 5px;
    padding-right: 5px;
}
.mt-8vh{
    margin-top: 15vh;
}
}
</style>
  