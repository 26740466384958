import { createStore } from 'vuex'

export default createStore({
  state: {
    activeTap: ''
  },
  getters: {
    getActiveTap (state) {
      return state.activeTap
    }
  },
  mutations: {
    ActiveTap (state, active) {
      state.activeTap = active
    }
  },
  actions: {
    setActiveTap ({ commit }, active) {
      commit('ActiveTap', active)
    }
  },
  modules: {}
})
